.spect-card {
    transition: all 0.3s ease;
    border: none;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .spect-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0,0,0,0.15);
  }
  
  .spect-image {
    height: 288px;
    object-fit: cover;
  }
  
  .price {
    font-weight: bold;
    color: #007bff;
  }
  
  .quantity-selector {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    margin-left: 73px;
  }
  
  .quantity-btn {
    width: 30px;
    height: 30px;
    border: 1px solid #007bff;
    background-color: #fff;
    color: #007bff;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .quantity-btn:hover {
    background-color: #007bff;
    color: #fff;
  }
  
  .quantity-input {
    width: 50px;
    height: 30px;
    text-align: center;
    border: 1px solid #ced4da;
    margin: 0 5px;
  }
  
  .quantity-input::-webkit-inner-spin-button, 
  .quantity-input::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
  
  .add-to-cart-btn {
    width: 100%;
    transition: all 0.3s ease;
  }
  
  .add-to-cart-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,123,255,0.3);
  }
  
  .tooltip {
    opacity: 1 !important;
  }
  
  .tooltip-inner {
    background-color: #dc3545 !important;
    color: white;
  }
  
  .tooltip .arrow::before {
    border-top-color: #dc3545 !important;
  }
  
  .spects_section {
    background: whitesmoke;
    margin-top: -23px;
  }
  
  h1#headspects {
    text-align: center;
    font-family: sans-serif;
    font-size: 44px;
    font-weight: 600;
    color: darkblue;
    padding-top: 30px;
    padding-bottom: 20px;
  }
  
  .card-body {
    background: black;
    font-family: cursive;
    color: aliceblue !important;
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    height: 300px;
  }
  
  button#ctas {
    background: aliceblue;
    color: #4f3ddb;
    border-radius: 19px;
    font-weight: bolder;
    font-size: 15px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  