.statistics-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #fff;
  }
  
  .heading {
    flex: 2;
    min-width: 300px;
    margin-right: 20px;
  }
  
  .heading h1 {
    font-size: 2em;
    color: #333;
    margin: 0;
  }
  
  .stats-cards {
    display: flex;
    flex: 3;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
  
  .stats-card {
    background-color: #f5f5f5;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    margin: 10px;
    flex: 1 1 200px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .stats-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .stats-card h2 {
    font-size: 2em;
    color: #ff6600;
    margin: 0;
  }
  
  .stats-card p {
    font-size: 1em;
    color: #555;
    margin: 10px 0 0 0;
  }
  
  
  @media (max-width: 768px) {
    .statistics-section {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .heading {
      margin-bottom: 20px;
    }
  
    .stats-cards {
      justify-content: center;
    }
  }
  