.location-page {
    text-align: center;
    background-color: black;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .location-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    /* background-color: #ffffff; */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .location-left, .location-right {
    flex: 1;
    max-width: 50%;
  }
  
  .location-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .location-image img {
    width: 100%;
    max-width: 600px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .location-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .location-info, .location-contact {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    width: 100%;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: brown;
    font-family: cursive;
    font-size: 23px;
}
  
  .location-map iframe {
    width: 100%;
    height: 740px;
    border: 0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .location-buttons {
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 600px;
    margin-top: 20px;
  }
  
  .address-button, .google-maps-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .address-button:hover, .google-maps-button:hover {
    background-color: #0056b3;
  }
  .location-page h1 {
    text-align: center;
  font-size: 38px;
  font-family: cursive;
  font-weight: 900;
 
  margin-bottom: 15px;
  color: aliceblue;
 
  }