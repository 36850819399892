.all-products {
    text-align: center;
    padding: 20px;
  }
  
  .category-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .item {
    text-align: center;
    flex: 1 1 120px;
    max-width: 150px;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .item:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .item img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #e0e0e0;
  }
  
  .item p {
    margin-top: 10px;
    font-size: 0.9rem;
    color: #333;
  }
  
  .view-all {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
  
  .view-all a {
    text-decoration: none;
    font-size: 1rem;
    color: #007BFF;
    padding-left: 10px;
  }
  
  @media (max-width: 768px) {
    .category-container {
      flex-direction: column;
      align-items: center;
    }
    
    .item {
      max-width: 120px;
    }
    
    .item img {
      width: 80px;
      height: 80px;
    }
  }
  
  @media (max-width: 480px) {
    .item p {
      font-size: 0.8rem;
    }
    
    .view-all a {
      font-size: 0.9rem;
    }
  }
  
  .all-products h1 {
    text-align: center;
    font-size: 31px;
    font-family: sans-serif;
    padding-bottom: 24px;
    padding-top: 10px;
    font-weight: 700;
}