.card-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: #2c0f06;
    padding: 20px;
  }
  
  .card {
    background-color: #55251b;
   
    border-radius: 10px;
    padding: 20px;
    margin: 10px;
    flex: 1 1 300px;
    max-width: 300px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
  
  .icon {
    font-size: 40px;
    margin-bottom: 10px;
    color: #ffb347;
  }
  
  h3 {
    font-size: 20px;
    margin: 10px 0;
  }
  
  p {
    font-size: 16px;
    line-height: 1.5;
    color: #ccc;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .card-section {
      flex-direction: column;
      align-items: center;
    }
  
    .card {
      max-width: 100%;
      margin: 10px 0;
    }
  }
  