.navbar {
    padding: 10px 0;
  }
  
  img.logonazon {
    width: 123px;
    border-radius: 10px;
    height: 79px;
    margin-left: 17px;
}
  @media (max-width: 991px) {
    .navbar-nav {
      text-align: center;
    }
  }
  .navbar-light .navbar-toggler-icon {
    background-image: aqua !important;
}
.navbar-light .navbar-toggler-icon {
     background-image: none;
}

span.navbar-toggler-icon {
    font-size: 38px;
    color: blue;
    background-color: aquamarine;
}
.navbar-light .navbar-toggler-icon {
    background-image: url('../Images/Carousel/hamburger-menu-filled-icon-in-transparent-background-basic-app-and-web-ui-bold-line-icon-eps10-free-vector.jpg') !important; /* Replace 'your-image-url.png' with the actual URL of your image */
    background-size: cover; /* Ensure the image covers the element */
    background-repeat: no-repeat; /* Prevent the image from repeating */
}


a.nav-link {
    font-size: 20px;
    font-family: cursive;
    color: white;
}


nav.navbar.navbar-expand-lg.navbar-light.bg-light {
    background: #35333c !important;
    color: aqua !important;
   
} 