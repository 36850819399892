.services {
    text-align: center;
    padding: 20px;
    background: black;
}
  
  .services-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .service {
    background: black;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    width: 250px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    color: lightskyblue;
}
  
  .service:hover {
    transform: translateY(-10px);
  }
  
  .service-icon {
    font-size: 40px;
    margin-bottom: 10px;
  }
  
  .service-title {
    font-size: 20px;
    margin: 10px 0;
    font-family: cursive;
}
  
  .service-description {
    color: #555;
  }
  
  .service-description {
    color: lightcoral;
    font-size: 14px;
    font-family: cursive;
}

.services h1 {
    color:aliceblue;
    font-size: 42px;
    font-family: cursive;
    font-weight: 600;
    padding-bottom: 21px;
}